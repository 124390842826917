import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Facebook, Instagram, AppleMusic, Youtube, Spotify } from "../../svg";

const Base = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  & a:not(:last-child) {
    margin-right: 10px;

    ${media.lessThan("medium")`
      margin-right: 8px;
      `}
    ${media.lessThan("small")`
      margin-right: 12px;
      `}
  }

  & a {
    transition: 0.15s ease-in-out;
    color: #414241;
  }

  & a:hover {
    color: var(--primary);
    transform: scale(1.08);
  }

  & svg {
    width: 36px;
    ${media.lessThan("medium")`
        width: 32px;
      `}
    ${media.lessThan("small")`
        width: 30px;
      `}
  }
`;

export default function SocialIcons() {
  return (
    <Base>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"https://www.facebook.com/EmmaLouMusic/"}
      >
        <Facebook />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/emmaloumusic/"
      >
        <Instagram />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://music.apple.com/us/album/nooit-alleen/1154668815"
      >
        <AppleMusic />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/user/EmmaPaalvast"
      >
        <Youtube />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://open.spotify.com/artist/5rfNdMCJ9sYE8MddcND5Cw"
      >
        <Spotify />
      </a>
    </Base>
  );
}
