import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import MenuImage from "../MenuImage";
import MenuItem from "../MenuItem";
import Youtube from "../Youtube";
import Spotify from "../SpotifyPlayer";

import menuData from "../../data/menu.json";

const typeMap = {
  MenuImage,
  MenuItem,
  Youtube,
  Spotify,
};

const BP1 = "820px";
const BP2 = "700px";
const BP3 = "560px";

const Base = styled.nav`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1.3fr 1fr 1fr 0.85fr 1.15fr;
  grid-template-rows: 105px 115px 93px 100px 80px;
  grid-row-gap: 28px;
  grid-column-gap: 36px;
  background-color: white;

  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 16px;

  & a {
    text-decoration: none;
  }

  ${media.lessThan(BP1)`
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 92px 93px 93px 93px 80px;
    grid-row-gap: 24px;
    grid-column-gap: 32px;
  `}

  ${media.lessThan(BP2)`
    grid-template-columns: 1fr 1fr 1.1fr 1.1fr;
    grid-template-rows: 100px 93px 80px 80px 100px 80px;
  `}

  ${media.lessThan(BP3)`
    grid-template-columns: 1fr 0.9fr 0.9fr;
    grid-template-rows: 90px 80px 93px 93px 80px 80px 100px;
    grid-row-gap: 18px;
    grid-column-gap: 28px;
  `}
  ${media.lessThan("small")`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 80px 80px 90px 80px 80px 90px 80px;
    grid-row-gap: 18px;
    grid-column-gap: 18px;
  `}

  & .menu-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .youtube {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }

  & .photo2 {
    grid-column: 4 / span 2;
    grid-row: 2 / span 4;

    ${media.lessThan(BP1)`
      grid-column: 4 / span 2;
      grid-row: 3 / span 3;
    `}
    ${media.lessThan(BP2)`
      grid-column: 3 / span 2;
      grid-row: 3 / span 3;
    `}
    ${media.lessThan(BP3)`
      grid-column: 2 / span 2;
      grid-row: 3 / span 3;
    `}
    ${media.lessThan("small")`
      grid-column: 1 / span 1;
      grid-row: 3 / span 2;
    `}
  }

  & .photo1 {

    img {
      object-position: center 66%;
    }

    ${media.lessThan("small")`
      grid-column: 2 / span 1;
      grid-row: 6 / span 1;
    `}
  }

  & .photo3 {
    grid-column: 1 / span 1;
    grid-row: 3 / span 2;
    
    img {
      /* object-position: 50% 100% !important; */
    }

    ${media.lessThan(BP1)`
      grid-column: 1 / span 2;
      grid-row: 3 / span 2;
      img {
        object-position: 50% 58% !important;
      }
    `}

    ${media.lessThan(BP2)`
      grid-column: 1 / span 2;
      grid-row: 5 / span 2;
      height: 100%;

      /* img {
        object-position: 50% 50% !important;
      } */
    `}
    ${media.lessThan(BP3)`
      grid-column: 2 / span 1;
      grid-row: 7 / span 1;
      height: 100%;
    `}
    ${media.lessThan("small")`
      display: none;
    `}
  }

  & .spotify {
    grid-column: span 2;

    ${media.lessThan(BP2)`
      grid-column: 3 / span 2;
      grid-row: 6 / span 1;
    `}
    ${media.lessThan(BP3)`
      grid-column: 2 / span 2;
      grid-row: 6 / span 1;
    `}
    ${media.lessThan("small")`
      display: none;
    `}
  }

  & .coach {
    ${media.lessThan(BP1)`
      margin-top: 0px;
    `}
  }

  ${media.lessThan(BP2)`
    & .photo4 {
      display: none;
    }
  `}


`;

export default function MenuGrid() {
  return (
    <Base>
      {menuData.map((item, index) => {
        const Component = typeMap[item.type];

        return <Component key={index} {...item.props} />;
      })}
    </Base>
  );
}
