import React from "react";
import styled from "styled-components";

const MenuButtonBase = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  user-select: none;
`;

const Text = styled.div`
  font-family: Randy;
  font-size: 20px;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: currentColor;
  font-weight: 400;
`;

const MenuButtonMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  cursor: pointer;
  color: black;

  transition: color 0.15s;
`;

const Hamburger = styled.div`
  height: 18px;
  width: 40px;
  margin-left: 0px;
  position: relative;

  & > span {
    display: block;
    height: 1px;
    right: 0;
    background-color: currentColor;
    position: absolute;
    transform-origin: left;
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out,
      width 0.1s ease-in-out;
  }

  & > span:nth-child(1) {
    top: 0;
    width: 24px;
    transform: rotate(${p => (p.isOpen ? 45 : 0)}deg)
      scale(${p => (p.isOpen ? 1.1 : 1)});
  }
  & > span:nth-child(2) {
    top: 50%;
    width: ${p => (p.isOpen ? 0 : 20)}px;
    opacity: ${p => (p.isOpen ? 0 : 1)};
  }
  & > span:nth-child(3) {
    bottom: 0;
    width: 24px;
    transform: rotate(${p => (p.isOpen ? -45 : 0)}deg)
      scale(${p => (p.isOpen ? 1.1 : 1)});
  }
`;

export default function MenuButton({ isOpen, onClick }) {
  return (
    <MenuButtonBase onClick={onClick}>
      <MenuButtonMain>
        <Text>Menu</Text>
        <Hamburger isOpen={isOpen}>
          <span />
          <span />
          <span />
        </Hamburger>
      </MenuButtonMain>
    </MenuButtonBase>
  );
}
