import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import SocialIcons from "../SocialIcons";

const Base = styled.div`
  padding: 16px 0px;
  background-color: #f7f7f7;
  margin-top: 148px;
`;

const Credits = styled.div`
  font-size: 14px;
  color: #6f6f6f;
  flex: 1;
`;

const Inner = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0px 16px;
  display: flex;
  align-items: center;

  ${media.lessThan("small")`
    flex-direction: column;

    & ${Credits} {
      order: 2;
      font-size: 10px;
      margin-top: 8px;
    }
  `}
`;

const thisYear = new Date().getFullYear();

export default function Footer() {
  return (
    <Base>
      <Inner>
        <Credits>
          © Emma Lou {thisYear} | design Erik Verweij | Foto's Nienke de Groot
        </Credits>
        <SocialIcons />
      </Inner>
    </Base>
  );
}
