import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import media from "styled-media-query";
import { Link } from "gatsby";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

import { Agenda, Audio, Book, Contact, ID, Microphone, Video } from "../../svg";

const iconMap = {
  agenda: Agenda,
  audio: Audio,
  book: Book,
  contact: Contact,
  id: ID,
  microphone: Microphone,
  video: Video
};

const Base = styled(Link)`
  & svg {
    fill: #383838;
    transition: transform 0.15s ease-in-out;
  }

  transition: transform 0.15s ease-in-out;

  border: 1px solid #616161;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Randy;
  text-transform: uppercase;
  color: black;
  font-size: 22px;
  line-height: 1.2;
  border-radius: 1px;
  padding: 12px;

  ${media.lessThan("820px")`
    font-size: 20px;
  `}
  ${media.lessThan("700px")`
    font-size: 18px;
  `}
  ${media.lessThan("small")`

    & .icon {
      transform: scale(0.8);
    }
  `}

  ${media.greaterThan("small")`
    &:hover {
      border: 1px solid black;
      transform: scale(1.02) rotate(1deg);
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.12);

      & svg {
        /* fill: black; */
        transform: scale(1.1);
      }
    }
  `}

`;

export default function MenuItem({
  style,
  className,
  title,
  icon,
  iconSize,
  link,
  horizontal
}) {
  const Icon = iconMap[icon];

  return (
    <Base
      className={className}
      style={{ ...style, flexDirection: horizontal ? "row" : "column" }}
      to={link}
      onClick={evt => {
        if (link.indexOf("#agenda") > -1) {
          evt.preventDefault();
          navigate("/");

          setTimeout(() => {
            document
              .getElementById("agenda")
              .scrollIntoView({ behavior: "smooth" });
          }, 200);
        }

        clearAllBodyScrollLocks();
      }}
    >
      <div
        style={{
          marginBottom: horizontal ? 0 : 6,
          marginRight: horizontal ? 12 : 0
        }}
      >
        <Icon className="icon" width={iconSize} />
      </div>
      <div>{title}</div>
    </Base>
  );
}
