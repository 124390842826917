import React from "react";

export default function Youtube({
  videoId,
  className,
  style,
  width,
  height,
  autoplay
}) {
  return (
    <div className={className} style={{ ...style, width, height }}>
      <iframe
        title="youtube video"
        width={"100%"}
        height={"100%"}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=${Number(
          autoplay
        )}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}
