import React from "react";

export default function SpotifyPlayer({ url, className }) {
  return (
    <div className={className}>
      <iframe
        title="spotify"
        src={url}
        width="100%"
        height="80"
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
  );
}
