import React from "react";

import styled from "styled-components";
import media from "styled-media-query";

import { Logo } from "../../svg";

import SocialIcons from "../SocialIcons";

import { Link } from "gatsby";

const BaseDesktop = styled.header`
  margin-top: 56px;
  /* margin-bottom: 128px; */
  margin-bottom: 84px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 16px;
  display: flex;

  ${media.lessThan("medium")`
    margin-top: 56px;
    margin-bottom: 76px;
  `}

  ${media.lessThan("small")`
    margin-top: 32px;
    margin-bottom: 48px;
    flex-direction: column;
    align-items: center;

    ${p => !p.frontpage && "display: none;"}
  `}



  & .left {
    flex: 1;
  }

  & .logo {
    width: ${p => (p.frontpage ? 200 : 160)}px;

    ${media.lessThan("medium")`
      width: ${p => (p.frontpage ? 180 : 140)}px;
    `}
    ${media.lessThan("small")`
      width: 120px;
      margin-bottom: 26px;
    `}
  }
`;

export default function Top({ frontpage }) {
  return (
    <BaseDesktop frontpage={frontpage}>
      <div className="left" />
      <Link to="/">
        <Logo frontpage={frontpage} className={"logo"} />
      </Link>
      <SocialIcons />
    </BaseDesktop>
  );
}
