import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import media from "styled-media-query";

import Top from "../Top";
import Footer from "../Footer";
import DesktopMenu from "../DesktopMenu";
import MobileTop from "../MobileTop";

import { Helmet } from "react-helmet";

const Base = styled.div`
  ${media.lessThan("small")`
    margin-top: ${(p) => (p.frontpage ? 0 : "140px")};
  `}
`;

export default function Main({ title, children, frontpage, path }) {
  const data = useStaticQuery(query);

  const t = `${title} ${data.site.siteMetadata.titlePostfix}`;

  const description = data.site.siteMetadata.description;

  const img = `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`;

  const url = data.site.siteMetadata.siteUrl + path;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t}</title>
        <link rel="canonical" href={url} />
        <html lang={"nl"} />

        <meta name="description" content={description} />
        <meta name="title" content={t} />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta
          name="keywords"
          content={data.site.siteMetadata.keywords.join(", ")}
        />
        <meta name="og:url" content={url} />
        <meta name="og:title" content={t} />
        <meta name="og:description" content={description} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@Emma_Lou_Music" />
        <meta name="twitter:title" content={t} />
        <meta name="twitter:description" content={description} />

        <meta name="og:image" content={img} />
        <meta name="twitter:image" content={img} />

        {/* <meta
          name="google-site-verification"
          content="YTWloHhczGRLE8q0xJZsJRdOdDEY3efYKmyqV--NHFc"
        /> */}
      </Helmet>
      <Base frontpage={frontpage}>
        <Top frontpage={frontpage} />

        {!frontpage && <DesktopMenu />}
        {!frontpage && <MobileTop />}

        {children}
      </Base>
      <Footer />
    </>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titlePostfix
        siteUrl
        description
        author
        keywords
        image
      }
    }
  }
`;
