import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Link } from "gatsby";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import MenuButton from "./MenuButton";

import menuData from "../../data/menu.json";

import MenuItem from "../MenuItem";
import MenuImage from "../MenuImage";

import { Logo } from "../../svg";

const BAR_HEIGHT = 70;

const Base = styled.nav`
  ${media.greaterThan("small")`
    display: none;
  `}

  height: ${(p) => (p.show ? BAR_HEIGHT : 0)}px;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999999;
  transition: height 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;

  border-bottom: 1px solid #f1f1f1;
  background-color: white;
`;

const MenuBase = styled.nav`
  transition: opacity 0.15s ease-in-out;
  position: fixed;
  top: ${BAR_HEIGHT}px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 99999999;

  opacity: ${(p) => (p.open ? 1 : 0)};
  pointer-events: ${(p) => (p.open ? "all" : "none")};
  padding: 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  width: 100%;
  height: 100%;

  & .menu-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top center !important;
  }

  & .photo2 {
    display: none;
  }

  & a {
    text-decoration: none;
  }
`;

export default function MobileTop() {
  const [show, setShow] = React.useState(true);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuRef = React.useRef(null);

  const lastScrollPosition = React.useRef(
    typeof window === "undefined" ? 0 : window.scrollY
  );

  React.useEffect(() => {
    function onScroll() {
      const currentScroll = typeof window === "undefined" ? 0 : window.scrollY;

      if (
        currentScroll > lastScrollPosition.current &&
        show &&
        currentScroll > 40
      ) {
        setShow(false);
      } else if (currentScroll < lastScrollPosition.current && !show) {
        setShow(true);
      }

      lastScrollPosition.current = currentScroll;
    }

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [show]);

  return (
    <>
      <Base show={show}>
        <Link to="/">
          <Logo height="40px" />
        </Link>
        <MenuButton
          isOpen={menuOpen}
          onClick={() => {
            const newOpen = !menuOpen;

            if (newOpen) {
              disableBodyScroll(menuRef.current);
            } else {
              enableBodyScroll(menuRef.current);
            }

            setMenuOpen(newOpen);
          }}
        />
      </Base>
      <MenuBase ref={menuRef} open={menuOpen}>
        <Grid>
          {menuData
            .filter((x) => x.type === "MenuItem" || x.type === "MenuImage")
            .map((item, index) => {
              if (item.type === "MenuItem") {
                return <MenuItem key={index} {...item.props} />;
              }

              return <MenuImage key={index} {...item.props} />;
            })}
        </Grid>
      </MenuBase>
    </>
  );
}
