import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
// eslint-disable-next-line
import { squareImage } from "../../image";

export default function Image({
  style,
  className,
  photo,
  alt,
  objectPosition,
}) {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "8089.jpg" }) {
        ...squareImage
      }
      photo2: file(
        relativePath: { eq: "20240917-NienkedeGrootA7537-CR3.jpg" }
      ) {
        ...squareImage
      }
      photo3: file(relativePath: { eq: "paars.jpg" }) {
        ...squareImage
      }
      photo4: file(relativePath: { eq: "hof.jpeg" }) {
        ...squareImage
      }
    }
  `);

  const imageData = data[photo];

  return (
    <Img
      className={`menu-image ${className || ""}`}
      fluid={imageData.childImageSharp.fluid}
      imgStyle={{ objectPosition: objectPosition || "50% 50%" }}
      alt={alt}
      style={{ ...style, objectPosition }}
      fadeIn={true}
      durationFadeIn={100}
    />
  );
}
