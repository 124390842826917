import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import MenuGrid from "../MenuGrid";

const MenuButtonBase = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  user-select: none;
`;

const Text = styled.div`
  font-family: Randy;
  font-size: 28px;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  color: currentColor;
  font-weight: 400;
`;

const MenuButtonMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;

  cursor: pointer;
  color: black;

  transition: color 0.15s;

  &:hover {
    color: var(--primary-text);

    & > span {
      background-color: var(--primary-text);
    }
  }

  & > span {
    position: absolute;
    height: 1px;
    display: block;
    background-color: lightgrey;
    opacity: 0.5;
    display: none;
  }
`;

const Hamburger = styled.div`
  height: 18px;
  width: 40px;
  margin-left: 0px;
  position: relative;

  & > span {
    display: block;
    height: 1px;
    right: 0;
    background-color: currentColor;
    position: absolute;
    transform-origin: left;
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out,
      width 0.1s ease-in-out;
  }

  & > span:nth-child(1) {
    top: 0;
    width: 24px;
    transform: rotate(${(p) => (p.isOpen ? 45 : 0)}deg)
      scale(${(p) => (p.isOpen ? 1.1 : 1)});
  }
  & > span:nth-child(2) {
    top: 50%;
    width: ${(p) => (p.isOpen ? 0 : 20)}px;
    opacity: ${(p) => (p.isOpen ? 0 : 1)}ß;
  }
  & > span:nth-child(3) {
    bottom: 0;
    width: 24px;
    transform: rotate(${(p) => (p.isOpen ? -45 : 0)}deg)
      scale(${(p) => (p.isOpen ? 1.1 : 1)});
  }
`;

function MenuButton({ isOpen, onClick }) {
  return (
    <MenuButtonBase onClick={onClick}>
      <MenuButtonMain>
        <Text>Menu</Text>
        <Hamburger isOpen={isOpen}>
          <span />
          <span />
          <span />
        </Hamburger>
        <span
          style={{
            left: -15,
            bottom: 5,
            width: 150,
            transform: `rotate(2deg)`,
          }}
        />
        <span
          style={{
            left: 15,
            bottom: 1,
            width: 100,
            transform: `rotate(3deg)`,
          }}
        />
        <span
          style={{
            left: 40,
            bottom: -3,
            width: 60,
            transform: `rotate(3deg)`,
          }}
        />
      </MenuButtonMain>
    </MenuButtonBase>
  );
}

const Base = styled.nav`
  position: relative;
  margin-bottom: 90px;
  margin-top: -24px;

  ${media.lessThan("small")`
    display: none;
  `}
`;

const Layer = styled.div`
  transition: opacity 0.15s ease-in-out;
  position: absolute;
  z-index: 1000;
  left: 0px;
  right: 0px;
  top: 56px;
`;

// const Filler = styled.div`
//   height: 100px;
//   background: linear-gradient(to bottom, white 0%, transparent 100%);
// `;

export default function Menu() {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <Base>
      <MenuButton isOpen={isOpen} onClick={() => setOpen(!isOpen)} />

      <Layer
        style={{
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "all" : "none",
        }}
      >
        <MenuGrid />
        <div
          style={{
            height: 50,
            backgroundColor: "white",
            width: 960,
            margin: "auto",
          }}
        />
        {/* <Filler /> */}
      </Layer>
    </Base>
  );
}
